import React, { useEffect, useState } from 'react'
import {over} from 'stompjs';
import SockJS from 'sockjs-client';

var stompClient =null;
const TradeMonitor = () => {
    const [walletRiskRate, setWalletRiskRate] = useState([]); 
    const [liquidWallet, setLiquidWallet] = useState([]); 
    const [userData, setUserData] = useState({
        username: '',
        receivername: '',
        connected: false,
        message: ''
      });

      useEffect(() => {
        connect();
        }, []);
    useEffect(() => {
      console.log(userData);
    }, [userData]);

    const connect =()=>{
        let Sock = new SockJS('http://195.244.63.153:8089/ws');
        stompClient = over(Sock);
        stompClient.connect({},onConnected, onError);
    }

    const onConnected = () => {
        setUserData({...userData,"connected": true});
         stompClient.subscribe('/wallet-risk-rate', onMessageReceived);
         stompClient.subscribe('/liquid-wallet', onLiquidMessageReceived);
    }

    
   
    const onMessageReceived = (payload)=>{
       if(payload && payload.body){
    var payloadData = JSON.parse(payload.body);
    if(Array.isArray(payloadData)){
       
        setWalletRiskRate(payloadData);
    }else{
        //handle the error
    }
}else{
    //handle the error
}
    }

        const onLiquidMessageReceived = (payload)=>{
       if(payload && payload.body){
    var payloadData = JSON.parse(payload.body);
    if(Array.isArray(payloadData)){
       
        setLiquidWallet(payloadData);
    }else{
        //handle the error
    }
}else{
    //handle the error
}

    }
    

    const onError = (err) => {
        console.log(err);
        
    }


    return (
    <div className="container">
        <div className="wallet-box">
         
           <div className="wallet-content">
            <h2>Margin Trader </h2>
             <h5>Cüzdanların anlık risk oranları</h5>
                <ul className="wallet-messages">
                    {walletRiskRate.map((wallet,index)=>(
                        <li className={`message ${wallet.senderName === userData.username && "self"}`} key={index}>
                          <div className="avatar">Wallet : {wallet.id}</div>
                          <div className="avatar self">Rating: {wallet.rating}</div>
                        </li>
                    ))}
                </ul>

              
            </div>
              <div className="wallet-content">
            <h2>- </h2>
             <h5>Likide olan cüzdanlar</h5>
                <ul className="wallet-messages">
                    {liquidWallet.map((wallet,index)=>(
                        <li className={`message ${wallet.senderName === userData.username && "self"}`} key={index}>
                          <div className="avatar2">Wallet : {wallet.id}</div>
                          <div className="avatar2 self2">Rating: {wallet.rating}</div>
                        </li>
                    ))}
                </ul>

              
            </div>
      
        </div>
     
    </div>
    )
}

export default TradeMonitor
