import React from 'react'
import TradeMonitor from './components/TradeMonitor'

const App = () => {
  return (
    <TradeMonitor />
  )
}

export default App;

